var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"text-left py-1 px-1",attrs:{"xs12":"","sm2":"","md2":"","lg2":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"outlined":"","small":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("back")))])],1)],1),_c('v-spacer'),_c('v-flex',{staticClass:"py-2 text-right",attrs:{"xs12":"","sm6":"","md6":"","lg6":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"outlined":"","small":""},on:{"click":_vm.newProduct}},[_vm._v(" "+_vm._s(_vm.$t("new product"))+" ")])],1)],1),_c('v-divider'),_c('v-layout',{staticClass:"mt-1",attrs:{"row":"","wrap":"","justify-space-between":"","align":"end"}},[_c('v-flex',{staticClass:"text-left",attrs:{"xs12":"","sm12":"","md4":"","lg4":""}},_vm._l((_vm.menu),function(button){return _c('v-btn',{key:button.title,staticClass:"mx-1 mt-1",attrs:{"color":button.selected
            ? _vm.$store.state.secondaryColor
            : _vm.$store.state.primaryColor,"small":"","outlined":""},on:{"click":function($event){return _vm.sortBy(button.action)}}},[_c('span',{staticClass:"caption text-lowercase"},[_vm._v(_vm._s(button.title))])])}),1),_c('v-spacer'),(!_vm.selected)?_c('v-flex',{staticClass:"text-left py-1 px-1",attrs:{"xs12":"","sm12":"","md4":"","lg4":""}},[_c('v-select',{staticClass:"caption",attrs:{"dense":"","solo":"","label":_vm.$t('select business'),"item-text":"businessName","item-value":"id","items":_vm.ownersBusiness},on:{"change":_vm.onSelectShop},model:{value:(_vm.selectedShop),callback:function ($$v) {_vm.selectedShop=$$v},expression:"selectedShop"}})],1):_vm._e(),_c('v-flex',{staticClass:"mt-1 text-right",attrs:{"xs12":"","sm12":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","placeholder":"Search product"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-alert',[_c('v-data-table',{staticClass:"text-capitalize",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.filterProduct,"search":_vm.search},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"40"},on:{"click":function($event){return _vm.viewPicture(item)}}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":item.avatar},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"width":"5","indeterminate":"","color":_vm.$store.state.primaryColor}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.quantity.inStock",fn:function(ref){
            var item = ref.item;
return [(Number(item.quantity.inStock) > _vm.stockQuantityNotification)?_c('v-chip',{staticClass:"green--text",attrs:{"color":"white"}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(item.quantity.inStock))])]):_c('v-chip',{staticClass:"red--text",attrs:{"color":"white"}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(item.quantity.inStock))])])]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","left":"","color":_vm.$store.state.primaryColor},on:{"click":function($event){return _vm.viewProduct(item.id)}}},'v-icon',attrs,false),on),[_vm._v("visibility")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("details")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","left":"","color":_vm.$store.state.primaryColor},on:{"click":function($event){return _vm.updateStock(item.id)}}},'v-icon',attrs,false),on),[_vm._v("fas fa-edit")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("update stock")))])])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dark":"","max-height":"440"}},[_c('v-img',{attrs:{"src":_vm.picture_selected,"contain":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }